import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer.jsx";
import AdminHeader from "../layout/admin-header.jsx";
import { useDispatch, useSelector } from "react-redux";
import { esimsICCID, esimsV2 } from "../../services/api.jsx";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import {
  formatDateTime,
  checkDataSize,
  checkProviderCode,
} from "../../utils/reuse";
import "../promo-code/PromoCode.scss";
import BundleDetails from "./BundleDetails.jsx";

const UserEsims = () => {
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const location = useLocation();
  const userName = location.state?.userName;
  const userId = location.state?.userId;
  const toast = useRef();
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [currentBundle, setCurrentBundle] = useState({});
  const [visible, setVisible] = useState(false);
  const [esimDetailsList, setEsimDetailsList] = useState({});
  const [iccidValue, setIccidValue] = useState("")
  const sortFields = "createdTs";
  const sortOrder = -1;
  const initialFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    iccid: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const [searchFilter, setSearchFilter] = useState(initialFilters);

  const sortByDate = (a, b) => moment(b.createdTs).diff(moment(a.createdTs));

  const getEsimsICCID = useCallback(
    (iccid) => {
      setLoading(true);
      let sortedData = { active: [], pending: [] };
      let getResponse = (response) => {
        setLoading(false);
        if (response.result === "SUCCESS") {
          const esimsList = response.data ? response.data : [];
          if (esimsList) {
            if (
              esimsList.status === "Released" ||
              esimsList.status === "Downloaded"
            ) {
              sortedData.pending = [...sortedData.pending, esimsList];
            } else if (esimsList.status !== "Unknown") {
              sortedData.active = [
                ...sortedData.active,
                processActive(esimsList),
              ];
            }
            setEsimDetailsList((prevState) => ({
              active: [...(prevState.active || []), ...sortedData.active].sort(
                sortByDate
              ),
              pending: [
                ...(prevState.pending || []),
                ...sortedData.pending,
              ].sort(sortByDate),
            }));
          } else {
            setLoading(false);
            toast.current.show({
              severity: "warn",
              summary: "Warning",
              detail: "No records found",
            });
          }
        } else {
          setLoading(false);
          const error = response.error;
          toast.current?.show({
            severity: error.severity,
            summary: "Error",
            detail: error.errorMsg ? error.errorMsg : error.summary,
          });
        }
      };
      esimsICCID(iccid, headers, dispatch, getResponse);
    },
    [headers, dispatch]
  );
  
  const getEsims = useCallback(() => {
    setLoading(true);
    setEsimDetailsList({});
    let getResponse = (response) => {
      setLoading(false);
      if (response?.result === "SUCCESS") {
        const esimsData = Array.isArray(response.data) ? response.data : [];
        if (esimsData.length > 0) {
          for (const iccidItem of esimsData) {
            getEsimsICCID(iccidItem.iccid);
          }
        } else {
          setLoading(false);
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "No records found",
          });
        }
      } else {
        setLoading(false);
        const error = response?.error || {};
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
    esimsV2(userId, headers, dispatch, getResponse);
  }, [userId, headers, dispatch, getEsimsICCID]); 

  useEffect(() => {
    getEsims();
  }, [getEsims]);

  const processActive = (i) => {
    let queued = 0,
      active = 0,
      expired = 0;

    const obj = {
      ...i,
      activeBundlesData: i.activeBundlesData.map((e) => {
        if (e.state === "active") active += 1;
        else if (e.state === "queued" || e.state === "processing") queued += 1;
        else expired += 1;
        return e;
      }),
    };
    return { ...obj, queued, active, expired };
  };

  const status = (rowData) => {
    if (rowData.status === "Released" || rowData.status === "Downloaded") {
      return <span className="status pending">PENDING</span>;
    } else if (rowData.status !== "Unknown") {
      const { queued, active, expired } = processActive(rowData);
      return (
        <span className="status active">
          Active: {active}, Queued: {queued}, Expired: {expired}
        </span>
      );
    } else {
      return "-";
    }
  };

  const esimIccid = (iccid) => iccid.iccid;

  const purchasedOn = (time) => formatDateTime(time.createdTs);

  const provider = (provider) => {
    const providerMap = {
      1: 'eSIM-Go',
      2: 'Airalo',
      3: 'Cobira',
    };
  
    return providerMap[provider?.providerCode] || 'N/A';
  };

  const pendingValidityHeader = (data) => (
    <div className="expiry-column">
      <p>{data?.totalDurationInDays} days</p>
    </div>
  );

  const bundleDetails = (rowData) => {
    let percentage = rowData?.totalData
      ? (rowData?.remainingData / rowData?.totalData) * 100
      : 0;
    return (
      <div className="expiry-column">
        <p>{`${rowData?.country?.split("(")[0]} ${
          rowData?.country ? "|" : ""
        } ${
          rowData?.isUnlimited
            ? "Unlimited"
            : `${checkDataSize(rowData?.remainingData)}  / ${checkDataSize(
                rowData?.totalData
              )} ${"left"}`
        } `}</p>
        <div className="card mt-1">
          <PR.ProgressBar
            value={percentage}
            className="progress-bar"
          ></PR.ProgressBar>
        </div>
      </div>
    );
  };

  const handleSearch = (iccid) => {
    const newTabUrl = `/esim-details/${iccid}`;
    window.open(newTabUrl, '_blank');
  };

  const actionHeader = (bundle) => {
    return (
    <div className="esim-actions" key={bundle?.providerCode}>
      <PR.Tooltip target=".custom-target-icon" />

      {bundle.activeBundlesData &&
        checkProviderCode(bundle.providerCode) && (
          <i
            className="custom-target-icon pi pi-info-circle"
            onClick={() => getOrderInfo(bundle.activeBundlesData[0])}
            data-pr-tooltip="View Details"
            data-pr-position="left"
            data-pr-at="left top+1"
            data-pr-my="right center"
            style={{ cursor: "pointer" }}
          ></i>
        )}
             <i
            className="custom-target-icon pi pi-angle-right"
            onClick={() => handleSearch(bundle.iccid)}
            style={{ cursor: "pointer" }}
          ></i>
    </div>
    )}
  
  const getOrderInfo = (order) => {
    // navigate("/bundle-details", { state: order });
    setCurrentBundle(order);
    setVisible(true);
  };

  const bundleDetailsAction = (bundle) => (
    <div className="flex align-items-center justify-content-center details-button">
      {checkProviderCode(bundle.providerCode) && (
        <i
          onClick={getOrderInfo.bind(null, bundle)}
          className="pi pi-info-circle"
        ></i>
      )}
    </div>
  );

  const customerName = () => {
    return userName
  }

  const iccidChangeHandler = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    const value = numericValue;
    let _filters = { ...searchFilter };
    _filters["global"].value = value;
    setSearchFilter(_filters);
    setIccidValue(value);
  };

  const activeBundleList = (rowData) => {
    return (
      <PR.DataTable
        value={rowData.activeBundlesData.slice(1)}
        stripedRows
        className="plans-table inner-table"
        emptyMessage={<span className="text-gray-500">No results found</span>}
      >
        <PR.Column style={{ width: "3%" }}></PR.Column>
        <PR.Column style={{ width: "16%" }}></PR.Column>
        <PR.Column body={bundleDetails} style={{ width: "18%" }}></PR.Column>
        <PR.Column field="totalDurationInDays" body={esim => pendingValidityHeader(esim)}></PR.Column>
        <PR.Column></PR.Column>
        <PR.Column></PR.Column>
        <PR.Column></PR.Column>
        <PR.Column style={{ width: "5%" }}
          body={(data) =>
            bundleDetailsAction({
              ...data,
              providerCode: rowData?.providerCode,
            })
          }
          className="last-column"
        ></PR.Column>
      </PR.DataTable>
    );
  };

  return (
    <>
      <div className="main">
        <div className="layout-sidebar">
          <AdminHeader />
        </div>
        <div className="layout-content-wrapper">
          <section className="admin-users-section promo-code-section">
            <div className="grid grid-nogutter">
              <div className="col-12">
                <div className="heading-sec">
                  <div className="flex align-items-center justify-content-between mb-4">
                    <h1> eSIM Details </h1>
                    <div className="right-section flex align-items-center gap-3"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative">
              <span className="p-input-icon-right esim-search-field usereSIM-details">
                <i className="pi pi-search"/>
                <PR.InputText placeholder="Search by ICCID" value={iccidValue}
                    onChange={iccidChangeHandler}
                />
              </span>
              <PR.TabView activeIndex={0} className="esims-tabs">
                <PR.TabPanel header={"Activated"}>
                  <div className="users-data-table promocode-table esim-details-datatable">
                    <PR.DataTable
                      emptyMessage={"No active eSIMs"}
                      value={esimDetailsList?.active}
                      stripedRows
                      className="plans-table"
                      expandedRows={expandedRows}
                      onRowToggle={(e) => setExpandedRows(e.data)}
                      rowExpansionTemplate={activeBundleList}
                      loading={loading}
                      paginator
                      // responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      paginatorDropdownAppendTo={"self"}
                      filters={searchFilter}
                      rows={10}
                      let-i="rowIndex"
                      rowsPerPageOptions={[10, 20, 50]}
                      sortField={sortFields}
                      sortOrder={sortOrder}
                    >
                      <PR.Column
                        style={{ width: "3%" }}
                        expander={(esim) => esim.activeBundlesData.length > 1}
                      />
                      <PR.Column
                        field="iccid"
                        header="ICCID"
                        body={esimIccid}
                        headerClassName="promocode-column iccid-column"
                        sortable
                        style={{ width: "16%", textAlign:"left" }}
                      ></PR.Column>
                      <PR.Column
                        header="Customer"
                        style={{ width: "18%" }}
                        body={customerName}
                        sortable
                      ></PR.Column>
                      <PR.Column
                        body={(esim) => bundleDetails(esim?.activeBundlesData[0])}
                        header="Bundle Details"
                        style={{ width: "18%" }}
                      ></PR.Column>
                      <PR.Column
                        sortable
                        field="totalDurationInDays"
                        body={(esim) => pendingValidityHeader(esim?.activeBundlesData?.[0])}
                        header="Validity"
                      ></PR.Column>
                      <PR.Column
                        field="provider"
                        body={provider}
                        header="Provider Name"
                        sortable
                      ></PR.Column>
                      <PR.Column
                        sortable
                        field="createdTs"
                        body={purchasedOn}
                        header="Purchased On"
                      ></PR.Column>
                      <PR.Column
                        sortable
                        field="status"
                        body={status}
                        header="Status"
                      ></PR.Column>
                      <PR.Column
                        body={actionHeader}
                        header={"Actions"}
                        headerClassName="last-column"
                        style={{ width: "5%" }}
                      ></PR.Column>
                    </PR.DataTable>
                  </div>
                </PR.TabPanel>
                <PR.TabPanel header={"Pending"}>
                  <div className="users-data-table promocode-table esim-details-datatable pending-tab">
                    <PR.DataTable
                      loading={loading}
                      emptyMessage={"No Pending eSIMs"}
                      value={esimDetailsList?.pending}
                      paginator
                      // responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      paginatorDropdownAppendTo={"self"}
                      filters={searchFilter}
                      rows={10}
                      let-i="rowIndex"
                      rowsPerPageOptions={[10, 20, 50]}
                      sortField={sortFields}
                      sortOrder={sortOrder}
                    >
                      <PR.Column
                        field="iccid"
                        header="ICCID"
                        sortable
                        body={esimIccid}
                        headerClassName="promocode-column iccid-column"
                        style={{ width: "16%", textAlign:"left" }}
                      ></PR.Column>
                      <PR.Column
                        header="Customer"
                        style={{ width: "18%" }}
                        body={customerName}
                        sortable
                      ></PR.Column>
                      <PR.Column
                        header="Bundles details"
                        style={{ width: "18%" }}
                        body={(esim) => bundleDetails(esim?.activeBundlesData[0])}
                      ></PR.Column>
                      <PR.Column
                        field="totalDurationInDays"
                        body={(esim) => pendingValidityHeader(esim?.activeBundlesData?.[0])}
                        header="Validity"
                        sortable
                      ></PR.Column>
                      <PR.Column
                        field="provider"
                        body={provider}
                        header="Provider Name"
                        sortable
                      ></PR.Column>
                      <PR.Column
                        field="createdTs"
                        body={purchasedOn}
                        header="Date & Time"
                        sortable
                      ></PR.Column>
                      <PR.Column
                        field="status"
                        body={status}
                        header="Status"
                        sortable
                      ></PR.Column>
                      <PR.Column
                        body={actionHeader}
                        header={"Actions"}
                        headerClassName="last-column"
                        style={{ width: "5%" }}
                      ></PR.Column>
                    </PR.DataTable>
                  </div>
                </PR.TabPanel>
              </PR.TabView>
            </div>
          </section>

          <PR.Dialog
            header="My eSIM Details"
            visible={visible}
            draggable={false}
            resizable={false}
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
            maskClassName="esim-details"
          >
            <BundleDetails bundle={currentBundle} />
          </PR.Dialog>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default UserEsims;
