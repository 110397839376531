import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getAffiliateUsersList, affiliateActivateAPI, affiliateDeactivateAPI, createAffiliates, updateAffiliates } from "../../services/api";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css'
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { QRCode } from 'react-qrcode-logo';

const Affiliates = () => {
    const dispatch = useDispatch();
    const qrRef = useRef({});
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const appName = process.env?.REACT_APP_NAME;
    const affURL = process.env.REACT_APP_AFF_URL;
    const currentDate = new Date();
    const affiliateCalendarRef = useRef(null);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("name");
    const [sortKey, setSortKey] = useState("-name");

    const [users, setUsers] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(true);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [affiliateName, setAffiliateName] = useState('');
    const [affiliateEmail, setAffiliateEmail] = useState('');
    const [affiliatePhone, setAffiliatePhone] = useState('');
    const [affiliateStatus, setAffiliateStatus] = useState([]);
    const [affiliatesData, setAffiliatesData] = useState(true);

    const [isDialogVisible, setDialogVisible] = useState(false);
    const [createAffiliate, setCreateAffiliate] = useState(false);
    const [selectedAffiliateId, setSelectedAffiliateId] = useState(null);
    const [isDeactivationDialogVisible, setDeactivationDialogVisible] = useState(false)
    const [durationValue, setDurationValue] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [durationError, setDurationError] = useState("");
    const [affiliateNameActivate, setAffiliateNameActivate] = useState('');
    const [iso, setIso] = useState("gb"); // Default ISO for the UK (country code +44)
    const [phoneValue, setPhoneValue] = useState("");
    const [createLoading, setCreateLoading] = useState(false)
    const [enable, setEnable] = useState(false)
    const [disableText, setDisableText] = useState("")
    const [enableLoading, setEnableLoading] = useState(false)
    const [enableAPI, setEnableAPI] = useState({})
    const [affiliatePurchaseDate, setAffiliatePurchaseDate] = useState(null);
    const [affiliatePurchaseDateFrom, setAffiliatePurchaseDateFrom] = useState(null);
    const [affiliatePurchaseDateTo, setAffiliatePurchaseDateTo] = useState(null);
    const [affiliateData, setAffiliateData] = useState(true)
    const [searchTrigger, setSearchTrigger] = useState(Date.now()); 

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);

    const handleCheckboxChange = (rowData) => {
        const isActive = rowData?.status === 'ACTIVE';
        setSelectedAffiliateId(rowData?.id);
        setDurationValue(rowData?.revenueRecognitionPeriodInDays)
        setDiscountValue(rowData?.permanentDiscountPercentage)
        setAffiliateNameActivate(rowData?.name)
        if (isActive) {
            setDeactivationDialogVisible(true);
        } else {
            setDialogVisible(true);
        }
    };

    const handleAPISwitchBox = (rowData, newValue) => {
        setEnable(true)
        setDisableText(rowData.b2bApiEnabled === true ? "Disable API" : "Enable API")
        const updatedRowData = {...rowData, b2bApiEnabled: newValue }
        setEnableAPI(updatedRowData)
    };  

    const handleDeactivate = () => {
        const response = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate has been successfully deactivated.",
                });
                getAllAffiliateUsers()
                setDurationValue('')
                setDiscountValue('')
                setDiscountError('')
                setDurationError("")
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };
        affiliateDeactivateAPI(selectedAffiliateId, dispatch, headers, response);
        setDeactivationDialogVisible(false);
    };

    const handleActivateSubmit = async () => {
        if(discountValue > 100) {
            setDiscountError ("Value must not exceed 100")
            return
        } else {
            setDiscountError("")
        }
        const obj = {
            "revenueRecognitionPeriodInDays": durationValue,
            "permanentDiscountPercentage": discountValue
        };
        const response = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate has been successfully activated.",
                });
                getAllAffiliateUsers()
                setDialogVisible(false);
                setDurationValue('')
                setDiscountValue('')
                setDiscountError('')
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };
        affiliateActivateAPI(obj, dispatch, headers, selectedAffiliateId, response);
    }

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setAffiliateData(true)
    };

    const getAllAffiliateUsers = useCallback(async () => {
        if (affiliatesData && affiliateData) {
            setLoading(true);
            const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
            const obj = {
                offset: offset,
                limit: limit,
                sortField: sortField,
                sortOrder: sortOrderVal,
                name: affiliateName,
                email: affiliateEmail,
                phone: affiliatePhone,
                status: affiliateStatus.join(','),
                createdDateFrom: (affiliatePurchaseDateFrom !== null) ? affiliatePurchaseDateFrom : '',
                createdDateTo: (affiliatePurchaseDateTo !== null) ? affiliatePurchaseDateTo : '',
            };
            getAffiliateUsersList(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    const list = response.data;        
                    const result = list !== null ? list.slice(0, pageCount) : [];
                    if (result.length > 0) {
                        setLast(list.length <= pageCount);
                        setUsers(result);
                        setPaging(true);
                    } else {
                        setUsers([]);
                        setLast(true);
                        setPaging(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                    }
                } else {
                    setUsers([]);
                    setLast(true);
                    setPaging(false);
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
                setLoading(false);
            })
        }
    }, [affiliateData, sortOrder, offset, limit, sortField, affiliateName, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliatesData, affiliateStatus, affiliatePurchaseDateFrom, affiliatePurchaseDateTo])

    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers, searchTrigger])

    const handleFormSubmit = (values) => {
        setCreateLoading(true)
        if (durationValue === null || durationValue === '') {
            setDurationError("Enter revenue recognition period");
            setCreateLoading(false); // Stop loading if validation fails
            return;
        } else {
            setDurationError("");
        }
        if(discountValue > 100) {
            setDiscountError ("Value must not exceed 100")
            return
        } else {
            setDiscountError("")
        }
        const obj = {
            "name": values.name,
            "email": values.email,
            "phone": { "callingCode": values.callingCode, "localPhoneNumber": phoneValue },
            "afId": values.affiliateId,
            "websites": values.websites,
            "revenueRecognitionPeriodInDays": durationValue,
            "permanentDiscountPercentage": discountValue,
            "b2bApiEnabled": values.b2bApiEnabled
        };
        const response = (response) => {
            setCreateLoading(false)
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate has been successfully registered.",
                });
                getAllAffiliateUsers()
                setCreateAffiliate(false);
                setDurationValue('')
                setDiscountValue('')
                setDiscountError('')
                setDurationError("")
                formik2.resetForm()
            } else if (response.result === "FAILED" && response.error) {
                setCreateLoading(false)
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };
        createAffiliates(obj, headers, dispatch, response);
      }

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
        setAffiliateData(true)
    };   

    const getUrl = (values) => {
        const baseUrl = (appName === 'data2go' || appName === 'esimcrew') ? `${affURL}&referrer=afid=` : `${affURL}?afid=`;
        return `${baseUrl}${values}`;
    };

    const handleCopyClipboard = (values) => {
        const url = getUrl(values);
        navigator.clipboard.writeText(url)
        toast.current.show({
            severity: 'success',
            summary: 'Copied',
            detail: `Affiliate Link copied to clipboard!`,
            life: 3000,
        });
    };

    const downloadQRCode = async (id) => {
        const canvas = qrRef.current[id].querySelector('canvas');
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'affiliate_qrcode.png';
        link.click();
       /*  const affiliateLink = getUrl(affiliateLinks);
        try {
          const qrCodeDataUrl = await QRCode.toDataURL(affiliateLink);
          const link = document.createElement('a');
          link.href = qrCodeDataUrl;
          link.download = 'affiliate_qr_code.png'; // Filename for the downloaded QR code
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
           toast.current.show({ severity: 'error', summary: 'Error', detail: "Failed to generate QR code" });
        } */
      };

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 border-0 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul mobile-col-ul affiliate-table-body">
                            <li className="affiliateUserStatus">
                                <div className="column-label">Status</div>
                                <div className="column-data"><PR.InputSwitch checked={rowData.status === 'ACTIVE'} onChange={() => handleCheckboxChange(rowData)} /></div>
                            </li>
                            <li className="affiliateUserName">
                                <div className="column-label">Affiliate Name</div>
                                <div className="column-data">{rowData.name}</div>
                            </li>
                            <li className="affiliateUserEmail">
                                <div className="column-label">Email ID</div>
                                <div className="column-data">{rowData.email}</div>
                            </li>
                            <li className="affiliateUserPhone">
                                <div className="column-label">Phone Number</div>
                                <div className="column-data">{rowData?.phone && rowData?.phone.callingCode && rowData?.phone.localPhoneNumber && rowData.phone.callingCode !== '+' ? rowData?.phone?.callingCode + " " + rowData?.phone?.localPhoneNumber : '-'}</div>
                            </li>
                            <li className="affiliateId">
                                <div className="column-label">Affiliate ID</div>
                                <div className="column-data">{rowData.afId}</div>
                            </li>
                            <li className="affiliateId discount">
                                <div className="column-label">Discount Value</div>
                                <div className="column-data">
                                    {rowData?.permanentDiscountPercentage != null ? rowData.permanentDiscountPercentage === 0  ? rowData.permanentDiscountPercentage : `${rowData?.permanentDiscountPercentage}%` : "-"}
                                </div>
                            </li>
                            <li className="affiliateUserStatus">
                                <div className="column-label">Enable API</div>
                                <div className="column-data"><PR.InputSwitch checked={rowData.b2bApiEnabled === true} onChange={(e) => handleAPISwitchBox(rowData, e.value)} /></div>
                            </li>
                            <li className="actionsColumn">
                                <div className="column-label">Actions</div>
                                <div className="column-data">
                                    {<>
                                            <div ref={(el) => qrRef.current[rowData.id] = el} style={{display: 'none'}}>
                                                <QRCode
                                                    id={rowData.afId}
                                                    value={getUrl(rowData.afId)}
                                                    size={500}
                                                    logoImage={`brands/${appName}-icon.png`}
                                                    logoWidth={50}
                                                    logoHeight={50}
                                                />
                                            </div>
                                            <i
                                                className="pi pi-download"
                                                title="Download QR Code"
                                                style={{ color: 'red', marginRight: '0.7rem', cursor: 'pointer' }}
                                                onClick={() => downloadQRCode(rowData.id)} 
                                            />
                                    </>
                                    }
                                    {<i
                                        className="pi pi-copy"
                                        title="Copy Affiliate Link"
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => handleCopyClipboard(rowData.afId)}
                                    />
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const affiliatesDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    const removeLeadingZero = (value) => value.replace(/^0+/, "");

    // Initial values for Form 1
    const [initialValues1] = React.useState({
        searchAffiliateName: affiliateName,
        searchAffiliateEmail: affiliateEmail,
        searchAffiliatePhone: affiliatePhone,
        searchAffiliateStatus: affiliateStatus,
        searchPurchaseDate: affiliatePurchaseDate
    });

    // Initial values for Form 2
    const [initialValues2] = React.useState({
        affiliateId: '',
        name: '',
        lastName: '',
        email: '',
        phoneNumber: "",
        callingCode: "+44",
        websites: [],
        b2bApiEnabled: false
    });

    const validationSchema1 = Yup.object().shape({
        searchAffiliateName: Yup.string().trim(),
        searchAffiliateEmail: Yup.string().trim().email('Invalid email'),
        searchAffiliatePhone: Yup.string().trim(),
    });

    const validationSchema2 = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Name must be at least 3 characters')
            .max(50, 'Name must be 50 characters or less')
            .required('Name is required'),
        lastName: Yup.string()
            .max(50, 'Last name must be 50 characters or less')
            .notRequired(),
        email: Yup.string()
            .email('Invalid email address')
            .max(100, 'Email must be 100 characters or less')
            .required('Email is required'),
        phoneNumber: Yup.string()
            .matches(
                /^\+?[1-9]\d{1,14}$/,
                "Invalid Phone number"
            )
            .notRequired(),
        callingCode: Yup.string()
            .matches(/^\+\d+$/, "Invalid Calling Code")
            .notRequired(),
    });
    
    

    const resetUserForm = () => {
        formik1.resetForm();
        setAffiliateName('');
        setAffiliateEmail('');
        setAffiliatePhone('');
        setAffiliateStatus([]);
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setAffiliateData(true)
        setAffiliatePurchaseDate(null)
        setAffiliatePurchaseDateFrom(null)
        setAffiliatePurchaseDateTo(null)
    }

    const onHideHandler = () => {
        formik2.resetForm()
        setCreateAffiliate(false)
        setDiscountValue("")
        setDurationValue("")
        setDurationError("")
    }

    const resetHandler = () => {
        formik2.resetForm()
        setDiscountValue("")
        setDurationValue("")
        setPhoneValue("")
    }

    const handleAffiliateSelectedStatus = (val) => {
        setAffiliateStatus(val);
        setAffiliatesData(false);
    }


    const handleSubmit = (formData) => {
        setAffiliatesData(true);
        setAffiliateName(encodeURIComponent(trimFormData(formData.searchAffiliateName)));
        setAffiliateEmail(encodeURIComponent(trimFormData(formData.searchAffiliateEmail)));
        setAffiliatePhone(encodeURIComponent(trimFormData(formData.searchAffiliatePhone)));
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setAffiliateData(true)
        setSearchTrigger(Date.now())
    }

    const handleAffiliateSelectedDate = (e) => {
        if (e.value === null) {
            setAffiliatePurchaseDate(null)
            setAffiliatePurchaseDateFrom(null);
            setAffiliatePurchaseDateTo(null);
        } else {
            const date = e.value;
            const fromDate = utils.modifyCustomerDate(date[0]);
            const toDate = date[1] ? utils.modifyCustomerDate(date[1]) : fromDate;
            setAffiliatePurchaseDateFrom(fromDate);
            setAffiliatePurchaseDateTo(toDate);
            setAffiliatePurchaseDate(date)
    
            // Close the calendar if both dates are selected
            if (date?.length > 1 && date[1] != null) {
                affiliateCalendarRef.current.hide();
            }
        }
        setAffiliateData(false)
    };

    const trimFormData = (val) => {
        return val.trim();
    }

    const discountChange = (e) => {
        const newValue = e.value;
        setDiscountValue(newValue);  
    }

    useEffect(() => {
        if (discountValue <= 100) {
            setDiscountError("");
        } else {
            setDiscountError("Value must not exceed 100");
        }
    }, [discountValue]);

    const updateAPIDisable = (rowData) => {
        setEnableLoading(true)
        const request = {
            id: rowData.id,
            b2bApiEnabled: disableText === 'Enable API' ? true : false,
          }
        updateAffiliates(request, headers, dispatch, (response) => {
          setEnableLoading(false)
          if (response.result === "SUCCESS") {
            setEnable(false);
            setDisableText(disableText === 'Enable API' ? 'Disable API' : 'Enable API')
            getAllAffiliateUsers()
            setDurationValue('')
            setDiscountValue('')
            setDiscountError('')
            setDurationError("")
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: `${disableText === 'Disable API' ? "Disabled API successfully" : "Enabled API successfully"}`,
            });
          } else {
            const error = response.error || {};
            setEnableLoading(false)
            toast.current.show({
              severity: error.severity || "error",
              summary: "Error",
              detail: error.errorMsg ? error.errorMsg : error.summary || "An unknown error occurred.",
            });
          }
        });
      }

    const formik1 = useFormik({
        initialValues: initialValues1,
        validationSchema: validationSchema1,
        onSubmit: (values) => {
            handleSubmit(values); // Submit for the first form
        },
    });
    
    const formik2 = useFormik({
        initialValues: initialValues2,
        validationSchema: validationSchema2,
        onSubmit: (values) => {
            handleFormSubmit(values); // Submit for the second form
        },
    });
    

    const affiliateStatusOptions = [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
    ];

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-affiliateslist-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between m-flex">
                                        <h1>Affiliates</h1>
                                        <div className="right-section flex align-items-center gap-3">
                                            <PR.Button
                                                label="Create Affiliate User"
                                                className="export-button"
                                                icon="pi pi-plus"
                                                iconPos="left"
                                                onClick={() => setCreateAffiliate(true)}
                                                disabled={loading}
                                            />
                                            <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                                <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                            </PR.Button>
                                        </div>
                                    </div>
                                    <div className={`filter-right justify-content-between ${filterOpen ? "mobile-filter-section" : ""}`}>
                                        <form autoComplete="off" onSubmit={formik1.handleSubmit}>
                                            <div className='flex align-items-center mobile-flex'>
                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Affiliate Name" name="searchAffiliateName" onChange={formik1.handleChange} onBlur={formik1.handleBlur} value={formik1.values.searchAffiliateName} autoComplete="off" />
                                                    </span>
                                                    {formik1.errors.searchAffiliateName && formik1.touched.searchAffiliateName
                                                        ? <div className='error-message'>{formik1.errors.searchAffiliateName}</div>
                                                        : ''
                                                    }
                                                </div>


                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Email ID" name="searchAffiliateEmail" onChange={formik1.handleChange} onBlur={formik1.handleBlur} value={formik1.values.searchAffiliateEmail} autoComplete="off" />
                                                    </span>
                                                    {formik1.errors.searchAffiliateEmail && formik1.touched.searchAffiliateEmail
                                                        ? <div className='error-message'>{formik1.errors.searchAffiliateEmail}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Phone Number" name="searchAffiliatePhone"
                                                            onChange={(e) => utils.handlePhoneChange(e, formik1, 'searchAffiliatePhone')}
                                                            onBlur={formik1.handleBlur} value={formik1.values.searchAffiliatePhone} autoComplete="off" />
                                                    </span>
                                                    {formik1.errors.searchAffiliatePhone && formik1.touched.searchAffiliatePhone
                                                        ? <div className='error-message'>{formik1.errors.searchAffiliatePhone}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.MultiSelect filter resetFilterOnHide value={affiliateStatus} options={affiliateStatusOptions} onChange={(e) => handleAffiliateSelectedStatus(e.value)} name="searchOrderStatus" optionLabel="label" placeholder="Status" maxSelectedLabels={1} />
                                                    </span>
                                                    {formik1.errors.searchOrderStatus && formik1.touched.searchOrderStatus
                                                        ? <div className='error-message'>{formik1.errors.searchOrderStatus}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Calendar panelClassName="datte-range-calendar" value={affiliatePurchaseDate} onChange={handleAffiliateSelectedDate} selectionMode="range" readOnlyInput placeholder="Date Range" name="searchPurchaseDate" dateFormat="dd-mm-yy" maxDate={currentDate} showIcon showButtonBar numberOfMonths={2} ref={affiliateCalendarRef} className="customers-daterange" />
                                                    </span>
                                                    {formik1.errors.searchPurchaseDate && formik1.touched.searchPurchaseDate
                                                        ? <div className='error-message'>{formik1.errors.searchPurchaseDate}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input laptop-btns-block">
                                                    <span>
                                                        <PR.Button label="Search" type='submit' className="searchBtn" />
                                                        <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetUserForm} />
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <div className="card">
                                <div className="custom-table">
                                    <div className="custom-table-header">
                                        <div className="table-grid">
                                            <ul className="col-ul sorting-li mobile-col-ul affiliate-table-header">
                                                {affiliatesDataHeader('status', 'Status', 'affiliateUserStatus')}
                                                {affiliatesDataHeader('name', 'Affiliate Name', 'affiliateUserName')}
                                                {affiliatesDataHeader('email', 'Email ID', 'affiliateUserEmail')}
                                                {affiliatesDataHeader('phone', 'Phone Number', 'affiliateUserPhone')}
                                                <li className="affiliateId">Affiliate ID</li>
                                                <li className="affiliateId discount">Discount Value</li>
                                                <li className="affiliateId enable-api">Enable API</li>
                                                <li className="actionsColumn">Actions</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <PR.DataView
                                        loading={loading}
                                        value={users}
                                        itemTemplate={itemTemplate}
                                        emptyMessage={<span className="text-gray-500">No results found</span>}
                                    />
                                </div>
                            </div>

                        </div>
                        <PR.Dialog
                            visible={isDialogVisible}
                            dismissableMask={true}
                            draggable={false}
                            resizable={false}
                            header="Activate Affiliate"
                            onHide={() =>  { setDialogVisible(false); setDiscountError("") }}
                            className="affiliate-dialog"
                            maskClassName="affiliate-dialog-mask"
                        >
                            <div>
                                <p>By clicking on confirm, you are activating the <br/> <strong>{affiliateNameActivate}</strong> account.</p>
                                <div style={{ marginTop: '1rem' }}>
                                    <label htmlFor="revenue-recognition">Set revenue recognition period</label>
                                    <PR.InputNumber
                                        value={durationValue}
                                        onValueChange={(e) => setDurationValue(e.value)}
                                        min={0}
                                        inputId="revenue-recognition"
                                        className="days-input"
                                    />
                                    <span style={{ marginLeft: '0.5rem' }}>days</span>
                                </div>
                                <div style={{ marginTop: '1rem' }}>
                                    <label htmlFor="revenue-recognition">Discount value</label>
                                    <PR.InputNumber
                                        value={discountValue}
                                        onValueChange={discountChange}
                                        min={0}
                                        mode="decimal"
                                        minFractionDigits={0}
                                        maxFractionDigits={0}
                                        inputId="revenue-recognition"
                                        className="percentage-input"
                                    />
                                    <span style={{ marginLeft: '0.6rem' }}>%</span>
                                </div>
                                {discountError !== "" ? <small className="p-error">{discountError}</small> : null}
                                <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                                    <PR.Button onClick={() => setDialogVisible(false)} label="Cancel" className="confirm-button reset-btn min-width"/>
                                    <PR.Button onClick={handleActivateSubmit} label="Confirm" className="confirm-button min-width"/>
                                </div>
                            </div>
                        </PR.Dialog>

                        <PR.Dialog
                            visible={isDeactivationDialogVisible}
                            dismissableMask={true}
                            draggable={false}
                            resizable={false}
                            header="Confirm Deactivation"
                            onHide={() => setDeactivationDialogVisible(false)}
                            className="affiliate-dialog"
                            maskClassName="affiliate-dialog-mask"
                        >
                            <p>Are you sure you want to deactivate this affiliate?</p>
                            <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                                <PR.Button onClick={() => setDeactivationDialogVisible(false)} label="No" className="confirm-button  reset-btn min-width"/>
                                <PR.Button onClick={handleDeactivate} label="Yes" className="confirm-button min-width"/>
                            </div>
                        </PR.Dialog>
                        {users.length > 0 ? (
                            <div className="pagination">
                                <button
                                    type="button"
                                    onClick={() => paginate(page - 1)}
                                    className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Previous}
                                </button>
                                <span className="active"> {page} </span>
                                <button
                                    type="button"
                                    onClick={() => paginate(page + 1)}
                                    className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Next}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <PR.Toast ref={toast} position='top-right' />
                    </section>
                    <AdminFooter />
                </div>
                <PR.Dialog
                    header="Create Affiliate user"
                    visible={createAffiliate}
                    draggable={false}
                    resizable={false}
                    onHide={onHideHandler}
                    dismissableMask={true}
                    maskClassName="create-voucher-dialog-mask"
                    className="filter-dialog voucher-modal create-voucher-dialog"
                    >
                    <form className="filter-content create-affiliate" onSubmit={formik2.handleSubmit}>
                        <div className="grid mt-2">
                        <div className="col-12 md:col-6">
                            <p className="mb-2">First name</p>
                            <PR.InputText
                            value={formik2.values.name}
                            keyfilter={/^[a-zA-Z0-9-_ ]*$/}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                            placeholder="Enter First name"
                            className="w-full"
                            name="name"
                            disabled={createLoading}
                            />
                            {formik2.touched.name && formik2.errors.name && (
                            <small className="p-error">{formik2.errors.name}</small>
                            )}
                        </div>
                        <div className="col-12 md:col-6">
                            <p className="mb-2">Last name (Optional)</p>
                            <PR.InputText
                            value={formik2.values.lastName}
                            keyfilter={/^[a-zA-Z0-9-_ ]*$/}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                            placeholder="Enter Last name"
                            disabled={createLoading}
                            className="w-12"
                            name="lastName"
                            />
                            {formik2.touched.lastName && formik2.errors.lastName && (
                            <small className="p-error">{formik2.errors.lastName}</small>
                            )}
                        </div>
                        </div>
                        <div className="grid mt-2">
                            <div className="col-12 md:col-6">
                                <p className="mb-2">Email</p>
                                <PR.InputText
                                value={formik2.values.email}
                                onChange={formik2.handleChange}
                                onBlur={formik2.handleBlur}
                                placeholder="Enter Email address"
                                className="w-full"
                                name="email"
                                disabled={createLoading}
                                />
                                {formik2.touched.email && formik2.errors.email && (
                                <small className="p-error">{formik2.errors.email}</small>
                                )}
                            </div>
                            <div className="col-12 md:col-6">
                            <p className="mb-2">Phone number(Optional)</p>
                            <PhoneInput
                            inputProps={{
                                name: "phone",
                                required: true,
                                id: "phone-input",
                            }}
                            countryCodeEditable={false}
                            autoFormat={true}
                            enableSearch={true}
                            searchPlaceholder="Search"
                            onBlur={formik2.handleBlur}
                            placeholder="Enter Phone number"
                            country={iso} // Default country: UK (ISO code "gb")
                            value={formik2.values.phone}
                            onChange={(value, data) => {
                                let phoneValue = `+${value}`.replace(`+${data.dialCode}`, "");
                                phoneValue = removeLeadingZero(phoneValue);
                                formik2.setFieldValue("phoneNumber", `${phoneValue}`);
                                setPhoneValue(phoneValue);
                                formik2.setFieldValue("callingCode", `+${data.dialCode}`);
                            }}
                            isValid={(country) => {
                                if (country === 0) {
                                    setIso("gb"); // Default to UK if no country detected
                                }
                                return true;
                            }}
                            style={{ width: "100%" }}
                            disabled={createLoading}
                        />
                            </div>
                        </div>
                        <div className="grid mt-2">
                            <div className="col-12 md:col-6">
                                <p className="mb-2">Affiliate ID (Optional)</p>
                                <PR.InputText
                                value={formik2.values.affiliateId}
                                onChange={formik2.handleChange}
                                onBlur={formik2.handleBlur}
                                placeholder="Enter Affiliate ID"
                                className="w-full"
                                name="affiliateId"
                                disabled={createLoading}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <p className="mb-2">Supported websites (Optional)</p>
                                <PR.Chips
                                value={formik2.values.websites}
                                onChange={(e) => {
                                    formik2.setFieldValue("websites", e.value);
                                }}
                                onBlur={formik2.handleBlur}
                                placeholder="Supported Websites/Advertising Location"
                                className="w-full"
                                name="websites"
                                addOnBlur={true}
                                allowDuplicate={false}
                                disabled={createLoading}
                            />
                            </div>
                        </div>

                        <div className="grid mt-2">
                            <div className="col-12 md:col-6">
                                <div className="relative">
                                    <p className="mb-2">Revenue recognition period</p>
                                    <PR.InputNumber
                                        value={durationValue}
                                        onValueChange={(e) =>{
                                            setDurationValue(e.value)
                                            if (e.value !== null && e.value !== '') {
                                                setDurationError('');
                                            }
                                        }}
                                        min={0}
                                        inputId="revenue-recognition"
                                        className="days-input"
                                        disabled={createLoading}
                                    />
                                    <span className="label-position">days</span>
                                </div>
                                {durationError !== "" ? <small className="p-error">{durationError}</small> : null} 
                            </div>
                            
                            <div className="col-12 md:col-6">
                                <div className="relative">
                                    <p className="mb-2">Discount value</p>
                                    <PR.InputNumber
                                        value={discountValue}
                                        onValueChange={discountChange}
                                        min={0}
                                        mode="decimal"
                                        minFractionDigits={0}
                                        maxFractionDigits={0}
                                        inputId="revenue-recognition"
                                        className="percentage-input"
                                        disabled={createLoading}
                                    />
                                    <span className="label-position">%</span>
                                </div>
                                {discountError !== "" ? <small className="p-error">{discountError}</small> : null}
                            </div>
                            <div className="col-12 md:col-12">
                                <div>
                                    <label htmlFor="revenue-recognition" className="label-text">Enable B2B API</label>
                                    <PR.Checkbox
                                        inputId="b2bApiEnabled"
                                        name="b2bApiEnabled"
                                        checked={formik2.values.b2bApiEnabled}
                                        onChange={formik2.handleChange}
                                        onBlur={formik1.handleBlur}
                                        label="Enable B2B API"
                                    />
                                </div>
                            </div>
                        </div>
                    <div>
                        
                        <div className="buttons-sections flex align-items-center justify-content-between gap-3 mt-3">
                            <p></p>
                            <div className="flex align-items-center gap-3">
                            <PR.Button
                                label="Reset"
                                type="button"
                                className="confirm-button reset-btn min-width"
                                disabled={createLoading}
                                onClick={resetHandler}
                            />
                            <PR.Button
                                label="Create"
                                type="submit"
                                className="confirm-button min-width"
                                disabled={createLoading}
                            />
                            </div>
                        </div>
                        </div>
                    </form>
                </PR.Dialog>

                <PR.Dialog
                visible={enable}
                dismissableMask={true}
                draggable={false}
                resizable={false}
                header={disableText === 'Disable API' ? 'Disable API' : 'Enable API'}
                onHide={() => setEnable(false)}
                className="affiliate-dialog"
                maskClassName="affiliate-dialog-mask"
              >
                <p>Are you sure you want to {disableText === 'Disable API' ? 'Disable' : 'Enable'} this API?</p>
                <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                  <PR.Button onClick={() => setEnable(false)} label="No" className="confirm-button  reset-btn min-width" disabled={enableLoading} />
                  <PR.Button onClick={() => { if (enableAPI) { updateAPIDisable(enableAPI) } }} label="Yes" className="confirm-button min-width" disabled={enableLoading} />
                </div>
              </PR.Dialog>
            </div>
        </>
    );
};

export default Affiliates;