import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getUsersList, getAffiliateUsersList, affiliateusers } from "../../services/api";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import "../users/Users.scss"; // CSS
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { getCurrentTimestamp } from "../../utils/reuse.js";

const Users = () => {
    const appName = process.env?.REACT_APP_NAME;
    const timeStamp = getCurrentTimestamp()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emails = params.get('email');
    const customeremail = emails ? emails : ""
    const currentDate = new Date();
    const customerCalendarRef = useRef(null);
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("name");
    const [sortKey, setSortKey] = useState("-name");

    const [users, setUsers] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(true);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState('');
    const [affiliateUser, setAffiliateUser] = useState('');
    const [affiliateNames, setAffiliateNames] = useState([])
    const [email, setEmail] = useState(customeremail || '');
    const [userEmail, setUserEmail] = useState(email || '');
    const [customerPurchaseDate, setCustomerPurchaseDate] = useState(null);
    const [customerPurchaseDateFrom, setCustomerPurchaseDateFrom] = useState(null);
    const [customerPurchaseDateTo, setCustomerPurchaseDateTo] = useState(null);
    const [customerData, setCustomerData] = useState(true)
    const [searchTrigger, setSearchTrigger] = useState(Date.now()); 
    const [selectedAffiliate, setSelectedAffiliate] = useState({})
    const [editingRows, setEditingRows] = useState({});
    const [isDialogVisible, setDialogVisible] = useState(false)

    const [exportLoading, setExportLoading] = useState(false)
    const [customerName, setCustomerName] = useState("")
    const [affName, setAffName] = useState("")
    const [currentUserId, setCurrentUserId] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [dialogText, setDialogText] = useState("Save")
    const [affiliate, setAffiliate] = useState([])
    const [afId, setAfId] = useState("")

    const affiliateEmail = ""
    const affiliateStatus = ""
    const affiliatePhone = ""
    const affiliateName = ""

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setCustomerData(true)
    };

    const getAllAffiliateUsers = useCallback(async () => {
        setLoading(true);
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const obj = {
            offset: 0,
            limit: 1000,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: affiliateName,
            email: affiliateEmail,
            phone: affiliatePhone,
            status: affiliateStatus,
            createdDateFrom: "",
            createdDateTo: "",
        };
        getAffiliateUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                setAffiliate(list)
                const affiliateName = list.map(x => x.name)
                setAffiliateNames(affiliateName)
                const result = list !== null ? list.slice(0, pageCount) : [];
                if (result.length > 0) {
                    setPaging(true);
                } 
            } else {
                const error = response.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }, [ sortOrder, sortField, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliateStatus, affiliateName])


    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers])

    const getAllUsers = useCallback(async () => {
        if(customerData){
        setLoading(true);
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const encodedUserEmail = userEmail ? encodeURIComponent(userEmail) : email ? encodeURIComponent(email) : '';
        const obj = {
            offset: offset,
            limit: limit,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: userName,
            email: encodedUserEmail,
            phone: userPhone,
            affiliateName: affiliateUser,
            createdDateFrom: (customerPurchaseDateFrom !== null) ? customerPurchaseDateFrom : '',
            createdDateTo: (customerPurchaseDateTo !== null) ? customerPurchaseDateTo : '',
        };
        getUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                const result = list !== null ? list.slice(0, pageCount) : [];
                if (result.length > 0) {
                    setLast(list.length <= pageCount);
                    result.map(data => {
                        data.affiliateUser = (data?.affiliateFirstName ? data?.affiliateFirstName : '') + (data?.affiliateLastName ? ' ' + data?.affiliateLastName : '')
                        return null
                    })
                    setUsers(result);
                    setPaging(true);
                } else {
                    setUsers([]);
                    setLast(true);
                    setPaging(false);
                    toast.current.show({ severity: 'error', summary:'Error', detail: 'No records found' });
                }
            } else {
                setUsers([]);
                setLast(true);
                setPaging(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }}, [customerData, sortOrder, offset, limit, sortField, userName, userEmail, userPhone, affiliateUser, headers, dispatch, pageCount, email, customerPurchaseDateFrom, customerPurchaseDateTo])


    useEffect(() => {
        getAllUsers()
    }, [getAllUsers, searchTrigger])

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
        setCustomerData(true)
    };

    const actions = (rowData) => {
        return (
            <>
            <PR.Button
                icon="pi pi-eye"
                label="View Orders"
                className="view-order"
                onClick={() => navigate('/user-orders/' + rowData.userId)}
            />
            <PR.Button 
                icon= "pi pi-eye"
                className="view-order"
                label="View eSIMs"
                onClick={() => navigate(`/user-esims/${rowData.userId}`, { state: { userName: rowData.userName, userId: rowData.userId } })}
            />
            </>
        );
    };
    
    const exportCSV = (e) => {
        setExportLoading(true)
        e.preventDefault();
    
        const obj = {
            offset: 0,
            limit: 10000,
            sortField: sortField,
            sortOrder: sortOrder === 1 ? "asc" : "desc",
            name: userName,
            email: userEmail ? encodeURIComponent(userEmail) : email ? encodeURIComponent(email) : '',
            phone: userPhone,
            affiliateName: affiliateUser,
            createdDateFrom: (customerPurchaseDateFrom !== null) ? customerPurchaseDateFrom : '',
            createdDateTo: (customerPurchaseDateTo !== null) ? customerPurchaseDateTo : '',
        };
    
        getUsersList(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS' && response.data?.length > 0) {
                const sanitizeString = (str) => str.replace(/[^a-zA-Z0-9\u0C00-\u0C7F\u0400-\u04FF@_-]/g, '');
                const exportArray = response.data.map((user) => ({
                    userName: sanitizeString(user.userName) || '-',
                    email: user.email || '-',
                    phone: user.phone?.callingCode && user.phone?.localPhoneNumber
                        ? `${user.phone.callingCode} ${user.phone.localPhoneNumber}`
                        : user.phone?.localPhoneNumber || '-',
                    affiliateUser: `${user.affiliateFirstName || ''} ${user.affiliateLastName || ''}`.trim() || '-',
                    ...(appName === "esimcrew" && { crewId: user.additionalinfo?.crewID?.trim() ? sanitizeString(user.additionalinfo.crewID) : '-'}) // Handle empty string crewID
                }));
    
                const capitalizeFirstLetter = (str) => {
                    const mappings = {
                        userName: 'Customer Name',
                        email: 'Email ID',
                        phone: 'Phone Number',
                        affiliateUser: 'Affiliate Name',
                        ...(appName === "esimcrew" && { crewId: 'Crew ID' })
                    };
                    return mappings[str] || str.charAt(0).toUpperCase() + str.slice(1);
                };
    
                const csvContent =
                    "data:text/csv;charset=utf-8," +
                    Object.keys(exportArray[0]).map(capitalizeFirstLetter).join(",") +
                    "\n" +
                    exportArray
                        .map((entry) =>
                            Object.values(entry)
                                .map((value) => (typeof value === 'string' && (value.includes(' ') || value.includes(',')) ? `"${value}"` : value))
                                .join(",")
                        )
                        .join("\n");
    
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `bo.${appName}-customer-list-${timeStamp}.csv`);
                document.body.appendChild(link);
                link.click();
    
                toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Exported successfully' });
            } else {
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'No data available to export' });
            }
            setExportLoading(false)
        });

    };

    const renderAffiliateSection = (rowData) => {
        const currentAffiliate = selectedAffiliate[rowData.userId] || rowData?.affiliateUser;
        const isEditing = editingRows[rowData.userId]; 

        return isEditing ? (
            <PR.Dropdown
                showClear
                value={selectedAffiliate[rowData.userId] || ""}
                filter
                resetFilterOnHide
                onChange={(e) => handleAffiliateChange(e, rowData)}
                placeholder="Select Affiliate"
                options={affiliate
                    .filter(aff => aff.status === "ACTIVE")
                    .map(aff => ({ label: aff.name, value: aff.id }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
            />
        ) : (
            <> 
                {currentAffiliate ? (
                    <div className="affiliate-edit">
                        <span className="affiliate-name">{currentAffiliate}</span>
                        <div className="flex">
                            <PR.Button onClick={() => handleEditAffiliate(rowData)} >
                                <i className="pi pi-pencil"></i>
                            </PR.Button>
                            <PR.Button className="close" onClick={() => handleDeleteAffiliate(rowData)}>
                                <i className="pi pi-times"></i>
                            </PR.Button>
                        </div> 
                    </div>
                ) : (
                    <PR.Button className="assign-button" onClick={() => setEditingRows(prev => ({ ...prev, [rowData.userId]: true }))}>
                        + Assign Affiliate
                    </PR.Button>
                )}
            </>
        );
    }; 
    
    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul sorting-li mobile-col-ul">
                            <li className="userName">
                                <div className="column-label">Customer</div>
                                <div className="column-data">{rowData.userName || '-'}</div>
                            </li>
                            <li className="userEmail">
                                <div className="column-label">Email ID</div>
                                <div className="column-data">{rowData.email || '-'}</div>
                            </li>
                            <li className="userPhone">
                                <div className="column-label">Phone Number</div>
                                <div className="column-data">
                                    {rowData?.phone?.callingCode && rowData?.phone?.callingCode !== "+undefined" && rowData?.phone?.localPhoneNumber
                                    ? `${rowData.phone.callingCode} ${rowData.phone.localPhoneNumber || '-'}`
                                    : rowData?.phone?.localPhoneNumber || '-' }
                                </div>
                            </li>                           
                            <li className="affiliateUser">
                                <div className="column-label">Affiliate Name</div>
                                <div className="column-data">
                                    {renderAffiliateSection(rowData)}
                                </div>                         
                            </li>
                             { appName === 'esimcrew' && <li className="crewId">
                                <div className="column-label">Crew ID</div>
                                <div className="column-data">{rowData?.additionalinfo?.crewID}</div>
                            </li>}
                            <li className="actions-tab">
                                <div className="column-label">Actions</div>
                                <div className="column-data mobile-flex">{actions(rowData)}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };


    const usersDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    const [initialValues] = React.useState({
        searchUserName: userName,
        searchUserEmail: userEmail,
        searchUserPhone: userPhone,
        searchAffiliateUser: affiliateUser,
        searchPurchaseDate: customerPurchaseDate
    })

    const validationSchema = Yup.object().shape({
        searchUserName: Yup.string().trim(),
        searchUserEmail: Yup.string().trim().email('Invalid email'),
        searchUserPhone: Yup.string().trim(),
        searchAffiliateUser: Yup.string().trim(),
    })

    const resetUserForm = () => {
        formik.resetForm();
        setUserName('');
        setUserEmail('');
        setUserPhone('');
        setAffiliateUser('');
        setCustomerPurchaseDate(null)
        setCustomerPurchaseDateFrom(null)
        setCustomerPurchaseDateTo(null)
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        const url = new URL(window.location);
        url.searchParams.delete('email'); 
        window.history.pushState({}, '', url);
        window.location.reload() 
        setEmail('')
        setCustomerData(true)
    }

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.delete('email'); 
        window.history.pushState({}, '', url); 
        setEmail('')
    }, [setEmail])

    const handleSubmit = (formData) => {
       const trimmedFormData = utils.trimFormData(formData);
       setUserName(encodeURIComponent(trimmedFormData.searchUserName));
       setUserEmail((trimmedFormData.searchUserEmail) || email || "");
       setUserPhone(encodeURIComponent(trimmedFormData.searchUserPhone));
       setAffiliateUser(encodeURIComponent(trimmedFormData.searchAffiliateUser))
       setPage(1);
       setOffset(0);
       setLimit(pageCount + 1);
       setCustomerData(true)
       setSearchTrigger(Date.now())
    }

    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : [])
            .sort((a, b) => a.localeCompare(b));
    };    

    const handleCustomerSelectedDate = (e) => {
        if (e.value === null) {
            setCustomerPurchaseDate(null)
            setCustomerPurchaseDateFrom(null);
            setCustomerPurchaseDateTo(null);
        } else {
            const date = e.value;
            const fromDate = utils.modifyCustomerDate(date[0]);
            const toDate = date[1] ? utils.modifyCustomerDate(date[1]) : fromDate;
            setCustomerPurchaseDateFrom(fromDate);
            setCustomerPurchaseDateTo(toDate);
            setCustomerPurchaseDate(date)
    
            // Close the calendar if both dates are selected
            if (date?.length > 1 && date[1] != null) {
                customerCalendarRef.current.hide();
            }
        }
        setCustomerData(false)
    };

    const handleAffiliateChange = (e, rowData) => {
        if (!e.value) {    
            setSelectedAffiliate(prev => {
                const updated = { ...prev };
                delete updated[rowData.userId]; 
                return updated;
            });
    
            setEditingRows(prev => {
                const updated = { ...prev };
                delete updated[rowData.userId]; 
                return updated;
            });
    
            setCustomerName("");
            setAffName("");
            setCurrentUserId(null);
    
            return;
        }
        const selectedAffiliateObj = affiliate.find(aff => aff.id === e.value);
        setSelectedAffiliate(prev => ({
            ...prev,
            [rowData.userId]: e.value
        }));
        setCurrentUserId(rowData.userId);
        setCustomerName(rowData?.userName?.trim() ? rowData.userName : "-");
        setAffName(selectedAffiliateObj ? selectedAffiliateObj.name : "")
        setAfId(e.value)
        if(dialogText === "Edit"){
            setEditDialog(true)
        } else if(dialogText === "Save"){
            setDialogVisible(true)
        }
    };
    
    const handleDialogClose = () => {
        setDialogVisible(false);
        setEditingRows(prev => {
            const updated = { ...prev };
            delete updated[currentUserId]; 
            return updated;
        });
        setSelectedAffiliate(prev => {
            const updated = { ...prev };
            delete updated[currentUserId]; 
            return updated;
        });
        setCustomerName("");
        setAffName("");
        setCurrentUserId(null);
    };

    const handleEditAffiliate = (rowData) => {
        setEditingRows(prev => ({ ...prev, [rowData.userId]: true }))
        setDialogText("Edit")
    }

    const handleEditDialogClose = () => {
        setEditDialog(false);
        setEditingRows(prev => {
            const updated = { ...prev };
            delete updated[currentUserId]; 
            return updated;
        });
        setSelectedAffiliate(prev => {
            const updated = { ...prev };
            delete updated[currentUserId]; 
            return updated;
        });
        setCustomerName("");
        setAffName("");
        setCurrentUserId(null);
        setDialogText("Save")
    }

    const handleDeleteAffiliate = (rowData) => {
        setDeleteDialog(true)
        setCustomerName(rowData?.userName?.trim() ? rowData.userName : "-");
        setAffName(rowData?.affiliateFirstName + rowData?.affiliateLastName)
        setCurrentUserId(rowData.userId)
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialog(false)
        setCustomerName("")
        setAffName("")
    }

    const handleAddAffiliate = () => {
        setLoading(true)
        const obj = {
            ids: [
                currentUserId
            ],
            affiliateUserId: afId
        };
        const response = (response) => {
            setLoading(false)
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate Associated Successfully",
                });
                getAllAffiliateUsers()
                getAllUsers()
                handleDialogClose()
                handleEditDialogClose()
            } else if (response.result === "FAILED" && response.error) {
                setLoading(false)
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };    
        affiliateusers(obj, headers, dispatch, response);
    }

    const handleUpdateAffiliate = () => {
        setLoading(true)
        const obj = {
            ids: [
                currentUserId
            ],
            affiliateUserId: afId
        };
        const response = (response) => {
            setLoading(false)
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate Updated Successfully",
                });
                getAllAffiliateUsers()
                getAllUsers()
                handleDialogClose()
                handleEditDialogClose()
            } else if (response.result === "FAILED" && response.error) {
                setLoading(false)
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };    
        affiliateusers(obj, headers, dispatch, response);
    }

    const deleteAffiliate = () => {
        setLoading(true)
        const obj = {
            ids: [
                currentUserId
            ],
            affiliateUserId: 0
        };
        const response = (response) => {
            setLoading(false)
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate Removed Successfully",
                });
                getAllAffiliateUsers()
                getAllUsers()
                handleDeleteDialogClose()
            } else if (response.result === "FAILED" && response.error) {
                setLoading(false)
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };    
        affiliateusers(obj, headers, dispatch, response);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-userlist-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between">
                                        <div className="flex align-items-center justify-content-between width-full">
                                            <h2>Customers</h2>
                                        </div>
                                        <div className="right-section flex align-items-center gap-2">
                                            <PR.Button label="Export CSV" className="exportBtn" icon="pi pi-file-export" iconPos="left" onClick={exportCSV} disabled={exportLoading}/>
                                            <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                                <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                            </PR.Button>
                                        </div>
                                    </div>
                                    <div className={`filter-right mt-5 ${filterOpen ? "mobile-filter-section" : ""}`}>
                                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='flex align-items-center mobile-flex'>
                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Customer Name" name="searchUserName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchUserName} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserName && formik.touched.searchUserName
                                                        ? <div className='error-message'>{formik.errors.searchUserName}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Dropdown filter resetFilterOnHide placeholder="Affiliate Name" name="searchAffiliateUser" options={handleAffiliateOptions(affiliateNames)} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateUser} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateUser && formik.touched.searchAffiliateUser
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateUser}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input email-input">
                                                    <span>
                                                        <PR.InputText placeholder="Email ID" name="searchUserEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchUserEmail}  autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserEmail && formik.touched.searchUserEmail
                                                        ? <div className='error-message'>{formik.errors.searchUserEmail}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText  placeholder="Phone Number" name="searchUserPhone"
                                                        onChange={(e) => utils.handlePhoneChange(e, formik,'searchUserPhone')}
                                                        onBlur={formik.handleBlur} value={formik.values.searchUserPhone}  autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchUserPhone && formik.touched.searchUserPhone
                                                        ? <div className='error-message'>{formik.errors.searchUserPhone}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Calendar panelClassName="datte-range-calendar" value={customerPurchaseDate} onChange={handleCustomerSelectedDate} selectionMode="range" readOnlyInput placeholder="Date Range" name="searchPurchaseDate" dateFormat="dd-mm-yy" maxDate={currentDate} showIcon showButtonBar numberOfMonths={2} ref={customerCalendarRef} className="customers-daterange" />
                                                    </span>
                                                    {formik.errors.searchPurchaseDate && formik.touched.searchPurchaseDate
                                                        ? <div className='error-message'>{formik.errors.searchPurchaseDate}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Button label="Search" type='submit' className="searchBtn" />
                                                        <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetUserForm} />
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <div className="card">
                                <div className="custom-table">
                                    <div className="custom-table-header">
                                        <div className="table-grid">
                                            <ul className="col-ul sorting-li">
                                                {usersDataHeader('name', 'Customer', 'userName')}
                                                {usersDataHeader('email', 'Email ID', 'userEmail')}
                                                {usersDataHeader('phone', 'Phone Number', 'userPhone')}
                                                {usersDataHeader('affiliateName', 'Affiliate Name', 'affiliateUser')}
                                                { appName === 'esimcrew' && <li>Crew ID</li>}
                                                <li>Actions</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <PR.DataView
                                        loading={loading}
                                        value={users}
                                        itemTemplate={itemTemplate}
                                        emptyMessage={<span className="text-gray-500">No results found</span>}
                                    />
                                </div>
                            </div>

                        </div>
                        {users.length > 0 ? (
                            <div className="pagination">
                                <button
                                    type="button"
                                    onClick={() => paginate(page - 1)}
                                    className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Previous}
                                </button>
                                <span className="active"> {page} </span>
                                <button
                                    type="button"
                                    onClick={() => paginate(page + 1)}
                                    className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Next}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <PR.Toast ref={toast} position='top-right' />
                    </section>
                    <AdminFooter />
                </div>
                <PR.Dialog
                    visible={isDialogVisible}
                    dismissableMask={true}
                    draggable={false}
                    resizable={false}
                    header="Confirm Affiliate"
                    onHide={handleDialogClose}
                    className="affiliate-dialog affiliate-assign"
                    maskClassName="affiliate-dialog-mask"
                >
                    <div>
                        <div style={{ marginTop: '1rem' }}>
                        <p>Are you sure you want to associate this <strong>{customerName}</strong> with <strong>{affName}</strong> ?</p>
                        </div>
                        <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                            <PR.Button onClick={handleAddAffiliate} label="Confirm" className="confirm-button min-width"/>
                        </div>
                    </div>
                </PR.Dialog>
                <PR.Dialog
                    visible={editDialog}
                    dismissableMask={true}
                    draggable={false}
                    resizable={false}
                    header="Change Affiliate"
                    onHide={handleEditDialogClose}
                    className="affiliate-dialog affiliate-assign"
                    maskClassName="affiliate-dialog-mask"
                >
                    <div>
                        <div style={{ marginTop: '1rem' }}>
                        <p>Are you sure you want to associate this <strong>{customerName}</strong> with <strong>{affName}</strong> ?</p>
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                        <p>Changing the affiliate means that future order credits will no longer be assigned to any affiliate and may also impact the discount value for <strong> {customerName} </strong>.</p>
                        </div>
                        <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                            <PR.Button label="Confirm" onClick={handleUpdateAffiliate} className="confirm-button min-width"/>
                        </div>
                    </div>
                </PR.Dialog>
                <PR.Dialog
                    visible={deleteDialog}
                    dismissableMask={true}
                    draggable={false}
                    resizable={false}
                    header="Remove Affiliate"
                    onHide={handleDeleteDialogClose}
                    className="affiliate-dialog affiliate-assign"
                    maskClassName="affiliate-dialog-mask"
                >
                    <div>
                        <div style={{ marginTop: '1rem' }}>
                        <p>Are you sure you want to remove this <strong>{customerName}</strong> with <strong>{affName}</strong> ?</p>
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                        <p>Removing the affiliate means that future order credits will no longer be assigned to any affiliate and may also impact the discount value for <strong> {customerName} </strong>.</p>
                        </div>
                        <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                            <PR.Button label="Confirm" onClick={deleteAffiliate} className="confirm-button min-width"/>
                        </div>
                    </div>
                </PR.Dialog>
            </div>
        </>
    );
};

export default Users;

